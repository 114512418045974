@import '../../variables';

.desktop {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $background;
  background-image: url(background.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  .desktop-layer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .desktop-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    backdrop-filter: blur(20px);
  }
}
