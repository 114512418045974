.moviemoji-app {
  padding: 24px;
  height: 100%;

  .app-game,
  .app-splash,
  .app-winner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .app-splash {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.active {
      opacity: 1;
    }
    img {
      height: 100px;
      margin-bottom: 24px;
      box-shadow: inset 100px 0 #901313;
      padding-left: 2px;
    }
  }

  .app-game,
  .app-winner {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: inherit;
    }
  }

  .app-game {
    padding-top: 32px;
  }

  .app-winner {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .game-clue {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 36px;

    span {
      margin-bottom: 16px;
      font-size: 24px;
    }
  }

  .game-solution {
    width: 80%;
    margin: 0 auto;

    .input-solution {
      border: none;
      width: 100%;
      font-size: 24px;
      padding: 4px 8px;
      box-shadow: 0 2px white;
      background: transparent;
      color: white;
      text-align: center;

      &.error {
        box-shadow: 0 2px #9e1616;
      }
    }
  }

  .game-submit {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 4px 8px;
    background: rgba(black, 0.2);
    width: 100%;
    font-size: 10px;
    text-align: right;

    a {
      color: white;
    }
  }

  .emoji {
    background: #3d8ac4;
    padding: 8px 16px;
    border-radius: 3px;
    display: flex;
    transition: all 0.1s ease-in-out;

    img {
      width: 32px !important;
      height: 32px !important;
      margin-right: 8px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
