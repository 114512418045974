@import '../../variables';

.menu-bar {
  height: 24px;
  background: $black;
  box-shadow: 0 2px 10px rgba(black, 0.2);
  color: white;
  padding: 0 6px;

  .menu-option {
    display: inline-block;
    font-size: 12px;
    vertical-align: top;
    line-height: 24px;
    padding: 0 6px;
    height: 24px;
    > * {
      line-height: 22px;
      display: block;
    }

    &.system {
      i {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &.app {
      font-weight: bold;
    }
  }
}
