@import '../../variables';

@include keyframes(slide-up) {
  0% {
    opacity: 0;
    transform: translateY(10px) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

.window {
  @media (max-width: $mobile) {
    top: 24px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 40px !important;
    height: calc(100% - 40px - 24px) !important;
    width: 100% !important;
  }

  &.frame {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($black-1, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px $shadow, 0 0 0 1px $black;
  }

  &.new {
    @include animation('slide-up .2s ease-out');
  }

  &.active {
    .navbar {
      background: rgba($black, 0.9);
    }
  }
  .navbar {
    width: 100%;
    height: 20px;
    color: white;
    cursor: move;
    position: relative;
    z-index: 20;
    //background: rgba($black-2, 0.3);
    transition: background 0.1s;
    .title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
    }
    .action-buttons {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100%;
      &:hover i {
        color: rgba(white, 0.7);
      }
      button {
        border: none;
        outline: none;
        width: 20px;
        height: 100%;
        font-size: 12px;
        line-height: 18px;
        vertical-align: top;
        padding: 0;
        background: transparent;
        cursor: pointer;
        transition: all 0.1s;
        &:hover {
          background: $black-1;
        }
      }
      i {
        color: rgba(white, 0.5);
        transition: all 0.1s;
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 20px);
    z-index: 10;
    position: relative;
    overflow: auto;
    > * {
      width: 100%;
      border: none;
    }
  }
  .resizes {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: 20;
    &.tl {
      top: -4px;
      left: -4px;
      z-index: 30;
      cursor: nwse-resize;
    }
    &.t {
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      cursor: ns-resize;
    }
    &.tr {
      top: -4px;
      right: -4px;
      z-index: 30;
      cursor: nesw-resize;
    }
    &.r {
      top: -4px;
      right: -4px;
      height: calc(100% + 8px);
      cursor: ew-resize;
    }
    &.br {
      bottom: -4px;
      right: -4px;
      z-index: 30;
      cursor: nwse-resize;
    }
    &.b {
      left: -4px;
      bottom: -4px;
      width: calc(100% + 8px);
      cursor: ns-resize;
    }
    &.bl {
      bottom: -4px;
      left: -4px;
      z-index: 30;
      cursor: nesw-resize;
    }
    &.l {
      top: -4px;
      left: -4px;
      height: calc(100% + 8px);
      cursor: ew-resize;
    }
  }
}
