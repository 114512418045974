$background: #0b0b0c;
$black: #1a1a1c;
$black-1: #1a1a1c;
$black-2: #2d2d31;
$grey: #eceff1;
$shadow: rgba($background, 0.3);
$accent-text: white;

$mobile: 600px;

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
