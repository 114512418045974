@import '../../variables';

.context-menu {
  .menu {
    position: fixed;
    z-index: 10;
    background: rgba($black, 0.9);
    backdrop-filter: blur(10px);
    color: white;
    padding: 6px 0;
    min-width: 120px;
    box-shadow: 0 0 12px rgba($black, .5), 0 0 0 1px $black-2;
    .menu-option {
      padding: 4px 12px;
      margin-bottom: 2px;
      font-size: 12px;

      &:hover {
        background: rgba(white, 0.1);
      }

      > * {
        vertical-align: top;
      }

      i {
        font-size: 14px;
        margin-right: 6px;
        line-height: 16px;
      }

      span {
        display: inline-block;
        line-height: 14px;
      }
    }
  }
  .background-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
}
