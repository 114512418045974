@import './variables';

html,
body,
#root,
#root > .App {
  height: 100%;
  width: 100%;
  margin: 0;
  background: #0c0c0c;
  color: white;
  font-size: 12px;
  overflow: hidden;
}

* {
  font-family: 'Roboto Mono', monospace;
  outline: none !important;
  user-select: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.row {
  display: flex;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}
.col {
  justify-content: space-between;
  flex: 1;
}
