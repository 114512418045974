@import '../../variables';

@include keyframes(slide-up) {
  0% {
    opacity: 0;
    transform: translateY(10px) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

@include keyframes(slide-down) {
  0% {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px) translateZ(0);
  }
}

.dock-menu {
  background: rgba($black, 0.9);
  backdrop-filter: blur(10px);
  position: fixed;
  left: 0;
  bottom: 40px;
  width: 250px;
  min-height: 400px;
  opacity: 0;
  padding: 8px 0;

  &.open {
    opacity: 1;
    @include animation('slide-up .2s ease-out');
    z-index: 2;
  }

  &.close {
    @include animation('slide-down .2s ease-out');
    pointer-events: none;
  }

  .menu-app {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s;
    padding: 8px 16px;

    &:hover {
      opacity: 1;
      background: rgba(white, 0.1);
    }

    i {
      font-size: 24px;
    }
    span {
      padding-left: 8px;
    }
  }
}

.dock-menu-shadow {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dock {
  height: 40px;
  background: rgba($black, 0.9);
  backdrop-filter: blur(10px);
  z-index: 3;
  box-shadow: inset 0 1px 0 0 $black;

  .dock-apps,
  .dock-actions,
  .dock-separator {
    display: inline-block;
    vertical-align: middle;
  }

  .dock-separator {
    box-shadow: 1px 0 rgba($black-2, 0.5);
    margin-right: 6px;
    padding-right: 5px;
    height: 20px;
  }

  .dock-button {
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background: transparent;
    transition: all 0.2s;
    color: rgba(white, 0.5);
    &:hover {
      color: white;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      i {
        color: white;
      }
    }
    div {
      height: 100%;
    }
    i {
      width: 100%;
      height: 100%;
      line-height: 40px;
      font-size: 21px;
    }
  }
}
